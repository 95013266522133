.messages {
  /* padding: 5% 0; */
  overflow: auto;
  flex: auto;
  padding-bottom: 5px;
}

.infoMessage {
  padding: 8px 6px 0px 16px !important;
}
