
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Spoqa Han Sans Neo', sans-serif  !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiListItem-button:hover {
  background-color: #f6f7fa;
}

.emojiEffect{
  animation:mymove 3s ease-out forwards;
animation-iteration-count:3;

/* Safari and Chrome */
-webkit-animation:mymove 3s;
-webkit-animation-iteration-count:3;
}

@keyframes mymove
{ from {bottom:0px; opacity: 1;}
to {bottom:200px; opacity: 0}
}

@-webkit-keyframes mymove /* Safari and Chrome */
{
from {bottom:0px; opacity: 1;}
to {bottom:200px; opacity: 0;}
}