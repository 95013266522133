.top{
  line-height: 1.38;
  text-align: right;
  margin-top: 0pt;
  margin-bottom: 0pt;
};

.span{
  font-size: 11pt;
  font-family: Arial;
  color: #000000;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
};

.bold {
  font-size: 11pt;
  font-family: Arial;
  color: #000000;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
};
.list{
  list-style-type: decimal;
  font-size: 11pt;
  font-family: Arial;
  color: #000000;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
};
 
.td1 {
  border-left: solid #999999 1pt;
  border-right: solid #999999 1pt;
  border-bottom: solid #999999 1pt;
  border-top: solid #999999 1pt;
  vertical-align: top;
  background-color: #f3f3f3 !important;
  padding: 5pt 5pt 5pt 5pt;
  overflow: hidden;
  overflow-wrap: break-word;
};

.td2{ 
  border-left: solid #999999 1pt;
  border-right: solid #999999 1pt;
  border-bottom: solid #999999 1pt;
  border-top: solid #999999 1pt;
  vertical-align: top;
  padding: 5pt 5pt 5pt 5pt;
  overflow: hidden;
  overflow-wrap: break-word;
};

.table{
    border:none;border-collapse:collapse;
}