.messageBox {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  font-family: NotoSansCJKkr;
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1em;
  margin-bottom: 0;
  margin-top:5px;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 3px 2%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: NotoSansCJKkr;
  font-size: 15px;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: rgba(95, 75, 139, 1);
}

.backgroundLight {
  background: #f3f3f3;
}

.messages > button:after{ 
  position:absolute;
  content:"";
  width: 0px;
  top:calc(50% - 3px);
  left:calc(50% - 5px);
  height: 0px;
  border-top: 8px solid rgba(0,0,0,0.5);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;

}
.messages > button:focus{
  outline:none
}

.hoverPointer {
  cursor:pointer;
}

