.form {
  display: flex;
  border-top: 2px solid #d3d3d3;
  margin-bottom: 0;
}

.input {
  border: none;
  border-radius: 0;
  padding: 0 10px;
  width: 85%;
  font-size: 1.2em;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.sendButton {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: rgba(95, 75, 139, 1);
  padding: 20px;
  display: inline-block;
  border: none;
  width: 15%;
}
